import { createContext, useReducer, useContext } from 'react';
import reactIntlUniversal from 'react-intl-universal';

import { Actions } from './reducers';

import enUS from '../../static/utils/i18n/en-US.json';
import esMX from '../../static/utils/i18n/sp-US.json';

const server = 'prod';

const reducer = (state, action) => {
  const getAction = Actions[action.type];
  const updateAction = getAction && getAction(state, action);
  if (server === 'dev') {
    console.group(`Dispatch Action: ${action.type}`);
    console.info('Prev State');
    console.table(state, 'background: #222; color: #bada55');
    console.debug('New State');
    console.table({ ...state, ...updateAction });
    console.groupEnd();
  }
  return { ...state, ...updateAction };
};

const state = {
  language: 'en-US',
};
const initialState = state;

const dispatch = function() {
  // any
};

const intl = reactIntlUniversal;

export const AppStateContext = createContext({ state, dispatch, intl });

export function AppStateContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch, intl };

  function loadLocales() {
    const locales = {
      'en-US': enUS,
      'es-MX': esMX,
    };
    return reactIntlUniversal.init({
      locales,
      currentLocale: state.language,
    });
  }

  loadLocales();
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppContext = () => useContext(AppStateContext);
